import {
  Chip,
  Icon,
  IconButton,
  Stack,
  TableHeadProps,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  DATE_FORMAT_RANGE_FNS,
  GetPayableFeesQuerySort,
  PayableFee,
  PayerType,
  SORT_DIRECTION,
} from '@schooly/api';
import { useNotifications } from '@schooly/components/notifications';
import { CURRENCY_SYMBOLS } from '@schooly/constants';
import {
  ChevronLeftIcon,
  CopyIcon,
  GridCell,
  GridHead,
  NewTabIcon,
  Price,
  SortableCell,
  TypographyWithOverflowHint,
} from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { formatPhoneNumberWithCode } from '@schooly/utils/phone-number';
import { getUserFullName } from '@schooly/utils/user-helpers';
import { differenceInDays, format } from 'date-fns';
import { FC, MouseEventHandler, ReactNode, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { payableFeeStatusColor } from '../../components/common/PayableFees/helpers';

const SPACING = 8 * 2;

export const PAYABLE_FEES_COL_WIDTH = {
  payer_1: 100 + SPACING,
  invoiceNum_2: 60 + SPACING,
  invoiceDate_3: 70 + SPACING,
  dueDate_4: 70 + SPACING,
  student_5: 50 + SPACING,
  discount_6: 70 + SPACING,
  paid_7: 100 + SPACING,
  due_8: 100 + SPACING,
  total_9: 100 + SPACING,
  status_10: undefined, // status is flexible width
  expand_11: 20 + SPACING,
};

type PayableFeesHeaderProps = {
  sort?: GetPayableFeesQuerySort;
  onChangeSort: (v: GetPayableFeesQuerySort) => void;
  rightIcon?: ReactNode;
} & TableHeadProps;

export const PayableFeesHeader: FC<PayableFeesHeaderProps> = ({
  onChangeSort,
  rightIcon,
  sort,
  ...rest
}) => {
  const { formatMessage } = useIntl();

  const handleSort = (by: GetPayableFeesQuerySort['by']) => () => {
    onChangeSort({
      by,
      direction:
        by === sort?.by
          ? sort.direction === SORT_DIRECTION.ASC
            ? SORT_DIRECTION.DESC
            : SORT_DIRECTION.ASC
          : SORT_DIRECTION.ASC,
    });
  };

  const cellSort = sort ? { ...sort, columnTextId: sort.by } : undefined;

  return (
    <GridHead borderBottom {...rest}>
      <GridCell width={PAYABLE_FEES_COL_WIDTH.payer_1}>
        {formatMessage({ id: 'payableFees-payer' })}
      </GridCell>
      <GridCell width={PAYABLE_FEES_COL_WIDTH.invoiceNum_2}>
        {formatMessage({ id: 'payableFees-invoice' })} #
      </GridCell>
      <SortableCell
        width={PAYABLE_FEES_COL_WIDTH.invoiceDate_3}
        label={formatMessage({ id: 'payableFees-invoice-date' })}
        sort={cellSort}
        columnTextId="issue_date"
        onChangeSort={handleSort}
      />
      <SortableCell
        width={PAYABLE_FEES_COL_WIDTH.dueDate_4}
        label={formatMessage({ id: 'payableFees-due-date' })}
        sort={cellSort}
        columnTextId="due_date"
        onChangeSort={handleSort}
      />

      <GridCell width={PAYABLE_FEES_COL_WIDTH.student_5}>
        {formatMessage({ id: 'payableFees-student' })}
      </GridCell>
      <SortableCell
        width={PAYABLE_FEES_COL_WIDTH.discount_6}
        label={formatMessage({ id: 'payableFees-discount' })}
        sort={cellSort}
        columnTextId="discount"
        onChangeSort={handleSort}
      />
      <SortableCell
        width={PAYABLE_FEES_COL_WIDTH.paid_7}
        label={formatMessage({ id: 'payableFees-paid' })}
        sort={cellSort}
        columnTextId="paid"
        onChangeSort={handleSort}
      />
      <SortableCell
        width={PAYABLE_FEES_COL_WIDTH.due_8}
        label={formatMessage({ id: 'payableFees-due' })}
        sort={cellSort}
        columnTextId="due"
        onChangeSort={handleSort}
      />
      <SortableCell
        width={PAYABLE_FEES_COL_WIDTH.total_9}
        label={formatMessage({ id: 'payableFees-total' })}
        sort={cellSort}
        columnTextId="total"
        onChangeSort={handleSort}
      />
      <SortableCell
        label={formatMessage({ id: 'payableFees-status' })}
        sort={cellSort}
        columnTextId="status"
        onChangeSort={handleSort}
      />
      <GridCell width={PAYABLE_FEES_COL_WIDTH.expand_11} />
    </GridHead>
  );
};

type PayableFeeRowProps = {
  payableFee: PayableFee;
};

export const PayableFeeRow: FC<PayableFeeRowProps> = ({ payableFee }) => {
  const [isExpanded, setExpanded] = useState(false);
  const [rowHovered, setRowHovered] = useState(false);

  const handleExpandClick: MouseEventHandler = (e) => {
    e.preventDefault();
    setExpanded((e) => !e);
  };

  const hoverProps = {
    onMouseEnter: () => setRowHovered(true),
    onMouseLeave: () => setRowHovered(false),
    sx: {
      ...(rowHovered && {
        'td.MuiTableCell-root': {
          backgroundColor: 'background.default',
          '.MuiTypography-root': {
            color: 'primary.main',
          },
        },
      }),
    },
  };

  const discount = useMemo(() => {
    return payableFee.items.reduce<number>((acc, i) => acc + i.discount_amount, 0);
  }, [payableFee.items]);

  const link = payableFee.invoice_link_for_issuer;

  return (
    <>
      <TableRow {...hoverProps}>
        <GridCell borderBottom={isExpanded ? false : undefined}>
          <PayableFeePayerCellContent fee={payableFee} />
        </GridCell>
        <GridCell>
          <TypographyWithOverflowHint
            color="text.primary"
            onClick={
              link
                ? (e) => {
                    e.preventDefault();
                    window.open(link, '_blank');
                  }
                : undefined
            }
            sx={
              link
                ? {
                    cursor: 'pointer',
                    ':hover': {
                      textDecoration: 'underline',
                    },
                  }
                : undefined
            }
          >
            {payableFee.invoice_number || '–'}
          </TypographyWithOverflowHint>
        </GridCell>
        <GridCell>
          <TypographyWithOverflowHint color="text.primary">
            {payableFee.invoice_date
              ? format(newDateTimezoneOffset(payableFee.invoice_date), DATE_FORMAT_RANGE_FNS)
              : '–'}
          </TypographyWithOverflowHint>
        </GridCell>
        <GridCell>
          <TypographyWithOverflowHint color="text.primary">
            {payableFee.due_date
              ? format(newDateTimezoneOffset(payableFee.due_date), DATE_FORMAT_RANGE_FNS)
              : '–'}
          </TypographyWithOverflowHint>
        </GridCell>
        <GridCell>
          <PayableFeeStudentsCellContent fee={payableFee} />
        </GridCell>
        <GridCell>
          <Typography color="text.primary">
            {discount ? (
              <Price
                variant="body1"
                price={discount}
                currency={CURRENCY_SYMBOLS[payableFee.currency]}
              />
            ) : (
              '–'
            )}
          </Typography>
        </GridCell>
        <GridCell>
          <Typography color="text.primary">
            {payableFee.total_paid ? (
              <Price
                variant="body1"
                price={payableFee.total_paid}
                currency={CURRENCY_SYMBOLS[payableFee.currency]}
              />
            ) : (
              '–'
            )}
          </Typography>
        </GridCell>
        <GridCell>
          <Typography color="text.primary">
            {payableFee.due ? (
              <Price
                variant="body1"
                price={payableFee.due}
                currency={CURRENCY_SYMBOLS[payableFee.currency]}
              />
            ) : (
              '–'
            )}
          </Typography>
        </GridCell>
        <GridCell>
          <Typography color="text.primary">
            {payableFee.total_payment ? (
              <Price
                variant="body1"
                price={payableFee.total_payment}
                currency={CURRENCY_SYMBOLS[payableFee.currency]}
              />
            ) : (
              '–'
            )}
          </Typography>
        </GridCell>
        <GridCell>
          <PayableFeeStatusCellContent fee={payableFee} />
        </GridCell>

        <GridCell>
          <IconButton
            inverse
            onClick={handleExpandClick}
            size="medium"
            sx={{ transform: isExpanded ? 'rotate(90deg)' : 'rotate(-90deg)' }}
          >
            <ChevronLeftIcon />
          </IconButton>
        </GridCell>
      </TableRow>
      {isExpanded &&
        payableFee.items.map((feeItem, i) => (
          <TableRow key={i} {...hoverProps}>
            <GridCell borderBottom={i === payableFee.items.length - 1 ? true : false} />
            <GridCell py={1.5} colSpan={4}>
              <TypographyWithOverflowHint color="text.primary">
                {feeItem.label}
              </TypographyWithOverflowHint>
            </GridCell>
            <GridCell py={1.5}>
              <Typography color="text.primary">
                {feeItem.discount_amount ? (
                  <Price
                    variant="body1"
                    price={feeItem.discount_amount}
                    currency={CURRENCY_SYMBOLS[payableFee.currency]}
                  />
                ) : (
                  '–'
                )}
              </Typography>
            </GridCell>
            <GridCell py={1.5}>
              <Typography color="text.primary">–</Typography>
            </GridCell>
            <GridCell py={1.5}>
              <Typography color="text.primary">–</Typography>
            </GridCell>
            <GridCell py={1.5} colSpan={3}>
              <Typography color="text.primary">
                <Price
                  variant="body1"
                  price={feeItem.price_full}
                  currency={CURRENCY_SYMBOLS[payableFee.currency]}
                />
              </Typography>
            </GridCell>
          </TableRow>
        ))}
    </>
  );
};

const PayableFeePayerCellContent: FC<{ fee: PayableFee }> = ({ fee }) => {
  const { formatMessage } = useIntl();

  const renderContacts = ({ email, telephone }: { email?: string; telephone?: string }) => {
    if (!email && !telephone) return null;

    return (
      <Stack gap={0.5} mt={1} flex={1}>
        {email && (
          <ContactButton
            title={email}
            copyMessage={formatMessage({ id: 'clipboard-EmailCopied' })}
            copyContent={email}
          />
        )}
        {telephone && (
          <ContactButton
            title={formatPhoneNumberWithCode(telephone)}
            copyMessage={formatMessage({ id: 'clipboard-PhoneCopied' })}
            copyContent={telephone}
          />
        )}
      </Stack>
    );
  };

  const getTitle = () => {
    switch (fee.payer.type) {
      case PayerType.Company:
        return fee.payer.data.name;
      case PayerType.Default:
        return getUserFullName(fee.payer.data);
      default:
        const _: never = fee.payer;
        return _;
    }
  };

  const openPayer = () => {
    window.open(
      fee.payer.type === PayerType.Company
        ? `/companies/${fee.payer.data.id}`
        : `/parents/${fee.payer.data.relation_id}`,
      '_blank',
    );
  };

  const title = getTitle();

  return (
    <Tooltip
      PopperProps={{
        sx: {
          '& .MuiTooltip-tooltip': {
            maxWidth: 'unset',
            px: 1,
          },
        },
      }}
      title={
        <Stack>
          <Typography
            color="text.primary"
            textAlign="center"
            maxWidth={300}
            onClick={openPayer}
            sx={{
              cursor: 'pointer',
              ':hover': { textDecoration: 'underline', color: 'primary.main' },
            }}
          >
            {title}
          </Typography>
          {renderContacts(fee.payer.data)}
        </Stack>
      }
    >
      <div>
        <Typography color="text.primary" noWrap onClick={openPayer}>
          {title}
        </Typography>
      </div>
    </Tooltip>
  );
};

const ContactButton: FC<{ title: ReactNode; copyContent: string; copyMessage: string }> = ({
  title,
  copyContent,
  copyMessage,
}) => {
  const { showNotification } = useNotifications();

  return (
    <Stack
      alignSelf="stretch"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      py={0.25}
      px={1}
      gap={2}
      sx={(theme) => ({
        cursor: 'pointer',
        borderRadius: theme.spacing(0.5),
        '&:hover': { backgroundColor: theme.palette.background.default, '.icon': { opacity: 1 } },
      })}
      onClick={(e) => {
        e.preventDefault();
        navigator.clipboard.writeText(copyContent);
        showNotification({ message: copyMessage });
      }}
    >
      <Typography variant="h3">{title}</Typography>
      <Icon className="icon" sx={{ opacity: 0, transition: 'all .2s' }}>
        <CopyIcon />
      </Icon>
    </Stack>
  );
};

const PayableFeeStudentsCellContent: FC<{ fee: PayableFee }> = ({ fee }) => {
  if (!fee.students.length) return '–';

  return (
    <Tooltip
      title={
        <Stack m={-1}>
          {fee.students.map((s) => (
            <Stack
              key={s.id}
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              py={0.25}
              px={1}
              gap={2}
              sx={(theme) => ({
                cursor: 'pointer',
                borderRadius: theme.spacing(0.5),
                '&:hover': {
                  backgroundColor: theme.palette.background.default,
                  '.icon': { opacity: 1 },
                },
              })}
              onClick={(e) => {
                e.preventDefault();
                window.open(`/students/${s.id}#payablefees`, '_blank');
              }}
            >
              <TypographyWithOverflowHint variant="h3">
                {getUserFullName(s)}
              </TypographyWithOverflowHint>
              <Icon className="icon" sx={{ fontSize: 15, opacity: 0, transition: 'all .2s' }}>
                <NewTabIcon />
              </Icon>
            </Stack>
          ))}
        </Stack>
      }
    >
      <Typography sx={{ cursor: fee.students.length ? 'pointer' : undefined }} color="text.primary">
        {fee.students.length || '–'}
      </Typography>
    </Tooltip>
  );
};

const PayableFeeStatusCellContent: FC<{ fee: PayableFee }> = ({ fee }) => {
  const { formatMessage } = useIntl();

  const renderBadge = () => {
    switch (fee.status) {
      case 'overdue':
      case 'paid':
        const dueDate = newDateTimezoneOffset(fee.due_date);
        const fullyPaidDate = fee.fully_paid_date
          ? newDateTimezoneOffset(fee.fully_paid_date)
          : null;
        const compareDate = fee.status === 'overdue' ? new Date() : fullyPaidDate;

        const formatDistance = compareDate ? differenceInDays(dueDate, compareDate) : null;
        const color = fee.status === 'overdue' ? 'error' : 'success';

        if (!formatDistance) return null;

        const labelDaysLate = formatMessage(
          {
            id:
              fee.status === 'overdue'
                ? 'payableFees-overdue-days'
                : 'payableFees-overdue-days-late',
          },
          { days: Math.abs(formatDistance) },
        );

        const labelOnTime = formatMessage({ id: 'payableFees-on-time' });

        return (
          <Chip
            variant="outlined"
            label={
              fee.status === 'paid' && fullyPaidDate && fullyPaidDate < dueDate
                ? labelOnTime
                : labelDaysLate
            }
            color={color}
            sx={(theme) => ({
              borderColor: `${theme.palette[color].main} !important`,
              height: theme.spacing(2.5),
              '.MuiChip-label': {
                padding: `${theme.spacing(0, 0.75)} !important`,
              },
            })}
          />
        );

      default:
        return null;
    }
  };

  return (
    <Stack flexDirection="row" alignItems="center" gap={0.5}>
      <Typography sx={{ color: `${payableFeeStatusColor[fee.status]} !important` }}>
        {formatMessage({ id: `payableFees-status-${fee.status}` })}
      </Typography>
      {renderBadge()}
    </Stack>
  );
};
